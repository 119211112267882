import styled, { useTheme } from 'styled-components';
import { MAIN_SECTION_MAX_WIDTH } from '../constants';
const MainSectionInner = styled.div.withConfig({
  displayName: "MainSectionInner",
  componentId: "sc-1p9wk43-0"
})(({
  divider
}) => {
  const theme = useTheme();
  return {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: `${MAIN_SECTION_MAX_WIDTH}px`,
    width: '100%',
    borderTop: divider ? `1px solid ${theme.color['border-core-subtle']}` : 'none'
  };
});
export default MainSectionInner;