import styled from 'styled-components';
const GUTTER_SIZES = {
  default: 32,
  xs: 8,
  sm: 16,
  xl: 96
};
const ResultsSection = styled.div.withConfig({
  displayName: "ResultsSection",
  componentId: "mqeyn2-0"
})(["margin-bottom:", "px;"], props => GUTTER_SIZES[props.gutterSize || 'default']);
export default ResultsSection;