'use es6';

import { createSelector } from 'reselect';
import * as AppContext from '../constants/AppContext';
import { debugGate } from '../utils/env';
import { getAppContext, getIsPublicApp } from './app';
const getGates = state => state.auth.gates;
export const getIsUngated = createSelector([getGates], gates => name => gates.includes(name) || debugGate(name));
const getUser = state => state.auth.user;
export const getIsCustomer = createSelector([getAppContext], appContext => appContext === AppContext.IN_APP);
export const getUserDisplayName = createSelector([getUser, getIsPublicApp], (user, isPublic) => isPublic ? null : `${user.first_name} ${user.last_name.slice(0, 1)}.`);
export const getUserFirstName = createSelector([getUser, getIsPublicApp], (user, isPublic) => isPublic ? null : user.first_name);
export const getUserLastName = createSelector([getUser, getIsPublicApp], (user, isPublic) => isPublic ? null : user.last_name);
export const getUserEmail = createSelector([getUser], user => user.email);
export const getUserId = createSelector([getUser], user => user.user_id);
export const getIsHubSpotter = createSelector([getUserEmail], email => email.endsWith('@hubspot.com'));